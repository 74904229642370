import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ErrorBoundary } from "@sentry/nextjs";
import React from "react";

import ErrorPage from "@/components/ErrorPage";
import theme from "@/theme";

const RootLayout = ({ children }) => {
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div
            style={{
              height: "100dvh",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {children}
          </div>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default RootLayout;
