import createWebStorage from "redux-persist/lib/storage/createWebStorage";

import { GRAPHQL_ENDPOINT, SENTRY_DSN, SERVER_SETTINGS } from "@/settings";

export const getServerSettings = () => {
  const httpProtocol = SERVER_SETTINGS.backendSsl ? "https" : "http";
  const wsProtocol = SERVER_SETTINGS.backendSsl ? "wss" : "ws";

  return {
    ...SERVER_SETTINGS,
    graphQLUrl: `${httpProtocol}://${SERVER_SETTINGS.backendHost}/${GRAPHQL_ENDPOINT}/`,
    websocketUrl: `${wsProtocol}://${SERVER_SETTINGS.backendHost}/${GRAPHQL_ENDPOINT}/`,
    mediaUrl: `${httpProtocol}://${SERVER_SETTINGS.backendHost}/api/v1/tenant/media/`,
    reportingCSVUrl: `${httpProtocol}://${SERVER_SETTINGS.backendHost}/reporting/csv/`,
    crmEntityCsvUrl: `${httpProtocol}://${SERVER_SETTINGS.backendHost}/api/v1/custom-model/entity-csv/`,
    messageBlastCsvUrl: `${httpProtocol}://${SERVER_SETTINGS.backendHost}/api/v1/message-blast/`,
    landingPageMediaUrl: `${httpProtocol}://${SERVER_SETTINGS.backendHost}/api/v1/landing-page/landing-page-media/`,
  };
};

const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem() {
      return Promise.resolve();
    },
  };
};

export const createStorage = (name = "local") =>
  typeof window !== "undefined" ? createWebStorage(name) : createNoopStorage();

export const getSentryDsn = () => {
  const ENVIRONMENT_SENTRY_DSN =
    process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

  if (ENVIRONMENT_SENTRY_DSN) {
    return ENVIRONMENT_SENTRY_DSN;
  }

  if (SENTRY_DSN === "BUILD_SENTRY_DSN") {
    return undefined;
  }

  return SENTRY_DSN;
};
